import React, { useMemo, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useMutation, gql } from '@apollo/client';
import Button from '../../system/Button/Button';
import Input from '../../system/Input/Input';
import Select from '../../system/Select/Select';
import { InlineAlert, InlineAlertType } from '../../system/InlineAlert/InlineAlert';
import styles from './ContactForm.module.scss';
import { SUBMIT_CONTACT_FORM } from 'src/graphql/submitContactForm';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { getNames, getCode } from 'country-list';

interface ContactFormProps {
  isOpen: boolean;
  toggle: () => void;
}

interface ContactFormData {
  salutation: { value: string; label: string } | null;
  firstName: string;
  lastName: string;
  email: string;
  countryRegion: { value: string; label: string } | null;
  companyName: string;
  reasonForContact: { value: string; label: string } | null;
  message: string;
  jobRole: { value: string; label: string } | null;
  department: { value: string; label: string } | null;
  industry: { value: string; label: string } | null;
  consentToMarketing: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({ isOpen, toggle }) => {
  const [formData, setFormData] = useState<ContactFormData>({
    salutation: null,
    firstName: '',
    lastName: '',
    email: '',
    countryRegion: null,
    companyName: '',
    reasonForContact: null,
    message: '',
    jobRole: null,
    department: null,
    industry: null,
    consentToMarketing: false,
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const countryOptions = useMemo(() => {
    return getNames().map((name) => ({
      value: getCode(name) || '',
      label: name,
    })).sort((a, b) => a.label.localeCompare(b.label));
  },                             []);
  const [submitContactForm, { loading }] = useMutation(SUBMIT_CONTACT_FORM);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const renderSuccessMessage = () => (
    <div className={styles.successMessage}>
      <h2>Thank you for contacting us!</h2>
      <p>We have sent you a verification email which you will need to respond to in order for us to get in touch.</p>
      <Button onClick={toggle}>Close</Button>
    </div>
  );
  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <div className={styles.formGrid}>
        <div className={styles.inputGroup}>
          <Select
            label="Salutation"
            value={formData.salutation}
            onChange={handleSelectChange('salutation')}
            options={[
              { value: 'Mr.', label: 'Mr.' },
              { value: 'Ms.', label: 'Ms.' },
              { value: 'Mrs.', label: 'Mrs.' },
              { value: 'Dr.', label: 'Dr.' },
              { value: 'Prof.', label: 'Prof.' },
            ]}
          />
        </div>
        <div className={styles.inputGroup}>
          <Input
            type="text"
            label="First name*"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputGroup}>
          <Input
            type="text"
            label="Last name*"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputGroup}>
          <Input
            type="email"
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputGroup}>
          <Select
            label="Country/Region"
            value={formData.countryRegion}
            onChange={handleSelectChange('countryRegion')}
            options={countryOptions}
          />
        </div>
        <div className={styles.inputGroup}>
          <Input
            type="text"
            label="Company name*"
            name="companyName"
            value={formData.companyName}
            onChange={handleInputChange}
          />
        </div>
        <div className={`${styles.inputGroup} ${styles.fullWidth}`}>
          <Select
            label="Reason for contact*"
            value={formData.reasonForContact}
            onChange={handleSelectChange('reasonForContact')}
            options={[
              { value: 'sign-up', label: 'Sign up' },
              { value: 'general', label: 'General inquiry' },
              { value: 'demo', label: 'Request a demo' },
              { value: 'sales-follow-up', label: 'Request a sales follow up' },
              { value: 'product-support', label: 'Product support (customers only)' },
              { value: 'product-inquiry', label: 'Other product related inquiry' },
              { value: 'data-partnership', label: 'Data partnership inquiry' },
              { value: 'privacy-compliance', label: 'Privacy and compliance' },
              { value: 'website-feedback', label: 'Website feedback' },
            ]}
          />
        </div>
        <div className={`${styles.inputGroup} ${styles.fullWidth}`}>
          <Input
            type="textarea"
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputGroup}>
          <Select
            label="Job Role*"
            value={formData.jobRole}
            onChange={handleSelectChange('jobRole')}
            options={[
              { value: 'c-level', label: 'C Level' },
              { value: 'managing-director', label: 'Managing Director / General Manager' },
              { value: 'executive-vp', label: 'Executive VP' },
              { value: 'senior-vp', label: 'Senior Vice President' },
              { value: 'vp', label: 'Vice President' },
              { value: 'director', label: 'Director' },
              { value: 'senior-manager', label: 'Senior Manager' },
              { value: 'manager', label: 'Manager' },
              { value: 'specialist', label: 'Specialist' },
              { value: 'assistant', label: 'Assistant' },
              { value: 'other', label: 'Other' },
            ]}
          />
        </div>
        <div className={styles.inputGroup}>
          <Select
            label="Department*"
            value={formData.department}
            onChange={handleSelectChange('department')}
            options={[
              { value: 'advertising-pr', label: 'Advertising & PR' },
              { value: 'brand-management', label: 'Brand Management' },
              { value: 'board', label: 'Board (Management/Executive Board)' },
              { value: 'business-development', label: 'Business Development' },
              { value: 'category-management', label: 'Category Management' },
              { value: 'controlling', label: 'Controlling' },
              { value: 'consulting', label: 'Consulting' },
              { value: 'data-delivery-management', label: 'Data Delivery Management' },
              { value: 'finance', label: 'Finance' },
              { value: 'general-management', label: 'General Management' },
              { value: 'human-resources', label: 'Human Resources' },
              { value: 'innovation', label: 'Innovation' },
              { value: 'it', label: 'IT' },
              { value: 'key-account-management', label: 'Key Account Management' },
              { value: 'legal', label: 'Legal' },
              { value: 'logistics', label: 'Logistics' },
              { value: 'marketing', label: 'Marketing' },
              { value: 'market-intelligence', label: 'Market Intelligence' },
              { value: 'manufacturing', label: 'Manufacturing' },
              { value: 'operations', label: 'Operations' },
              { value: 'pricing', label: 'Pricing' },
              { value: 'procurement', label: 'Procurement' },
              { value: 'product-management', label: 'Product Management' },
              { value: 'research-development', label: 'Research & Development' },
              { value: 'sales', label: 'Sales' },
              { value: 'supply-chain', label: 'Supply Chain' },
              { value: 'trade-management', label: 'Trade Management' },
              { value: 'other', label: 'Other' },
            ]}
          />
        </div>
        <div className={styles.inputGroup}>
        <Select
          label="Industry"
          value={formData.industry}
          onChange={handleSelectChange('industry')}
          options={[
            { value: 'automotive', label: 'Automotive' },
            { value: 'consumer_goods', label: 'Consumer Goods' },
            { value: 'fashion_lifestyle', label: 'Fashion & Lifestyle' },
            { value: 'financial_services', label: 'Financial Services' },
            { value: 'healthcare', label: 'Healthcare' },
            { value: 'media_entertainment', label: 'Media and Entertainment' },
            { value: 'energy_environment', label: 'Energy & Environment' },
            { value: 'industrial_goods', label: 'Industrial Goods, Building, Construction' },
            { value: 'intercompany_business', label: 'Intercompany Business' },
            { value: 'professional_services', label: 'Professional Services' },
            { value: 'retail', label: 'Retail' },
            { value: 'technology', label: 'Technology' },
            { value: 'travel_logistics', label: 'Travel & Logistics' },
            { value: 'public_services', label: 'Universities, Public Services & Associations' },
          ]}
        />
        </div>
      </div>

      <div className={styles.checkboxContainer}>
      <FormControlLabel
        control={
          <Checkbox
            checked={formData.consentToMarketing}
            onChange={handleCheckboxChange}
            name="consentToMarketing"
            color="primary"
          />
        }
        label={
          <span>
            I consent to receive regular e-mail marketing communication
             about products and services including invitations to webinars
             and events from GfK Group and NürnbergMesse Group.
             You may withdraw your consent at any time with effect for the future.
          </span>
        }
      />
      </div>
      <Button type="submit" testId="submitContact" disabled={loading}>
        {loading ? 'Submitting...' : 'Submit'}
      </Button>
    </form>
  );
  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleSelectChange = (name: keyof ContactFormData) => (selectedOption: any) => {
    setFormData((prev) => ({
      ...prev,
      [name]: selectedOption,
    }));
  };

  // tslint:disable-next-line:ter-arrow-parens
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(null);

    const requiredFields: (keyof ContactFormData)[] = [
      'salutation',
      'firstName',
      'lastName',
      'email',
      'countryRegion',
      'companyName',
      'reasonForContact',
      'message',
      'jobRole',
      'department',
      'industry',
    ];

    const missingFields = requiredFields.filter((field) => {
      if (field === 'salutation' || field === 'countryRegion' || field === 'reasonForContact' || field === 'jobRole' || field === 'department' || field === 'industry') {
        return !formData[field]?.value;
      }
      return !formData[field];
    });

    if (missingFields.length > 0) {
      setErrorMessage(`Please fill in all required fields: ${missingFields.join(', ')}.`);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    try {
      const { data } = await submitContactForm({
        variables: {
          input: {
            salutation: formData.salutation?.value,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            countryRegion: formData.countryRegion?.value,
            companyName: formData.companyName,
            reasonForContact: formData.reasonForContact?.value,
            message: formData.message,
            jobRole: formData.jobRole?.value,
            department: formData.department?.value,
            industry: formData.industry?.value,
            consentToMarketing: formData.consentToMarketing,
          },
        },
      });

      const response = data.saveFormDataAndSendVerificationEmail;
      if (response.success) {
        setIsSubmitted(true);
      } else {
        setErrorMessage(response.message);
      }
    } catch (error: any) {
      console.error('Error submitting form:', error);
      setErrorMessage('An error occurred while submitting the form. Please try again.');
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalBody>
        <div className={styles.contactForm}>
          {!isSubmitted ? (
            <>
              <h1>Contact us</h1>
              {errorMessage && (
                <InlineAlert type={InlineAlertType.DataError}>
                  <p>{errorMessage}</p>
                </InlineAlert>
              )}
              {renderForm()}
            </>
          ) : (
            renderSuccessMessage()
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ContactForm;
