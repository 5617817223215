import { Auth, CognitoUser } from '@aws-amplify/auth';
import { AuthOptions } from '@aws-amplify/auth/lib-esm/types';
import React, { useEffect, useMemo, useState } from 'react';
import { IconContext } from 'react-icons';
import { ApolloProvider } from '@apollo/client';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import styles from './App.module.scss';
import Authenticator from './components/auth/authenticator';
import MyAccount from './components/pages/MyAccount/MyAccount';
import NotFound from './components/pages/NotFound/NotFound';
import PrivacyPolicy from './components/pages/PrivacyPolicy/PrivacyPolicy';
import EcosystemFooter from './components/system/EcosystemFooter/EcosystemFooter';
import EcosystemHeader from './components/system/EcosystemHeader/EcosystemHeader';
import { getConfig, isProduction } from './config';
import { createApolloClient } from './graphql/client';
import LayoutWrapper from './components/system/LayoutWrapper/LayoutWrapper';
import Dashboard from './components/pages/Dashboard/Dashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import DeliverableDownload from './components/pages/DeliverableDownload/DeliverableDownload';
import { ArchiveDirectDownload } from './components/ArchiveDownload/ArchiveDownload';
import Home from './components/pages/Home/Home';
import Landing from './components/pages/Landing/Landing';
import News from './components/pages/Ovn/News';
import LoginModal from './components/pages/Landing/LoginModal';
import Loading from './components/system/Loading/Loading';
import ContactForm from './components/pages/Landing/ContactForm';
// tslint:disable-next-line:function-name
function App() {
  const [currentUser, setCurrentUser] = useState<CognitoUser | undefined>(undefined);
  const [currentUserEmail, setCurrentUserEmail] = useState<string>('');
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [loadingState, setLoadingState] = useState(true);

  const config = getConfig();

  const toggleLoginModal = () => setIsLoginModalOpen(!isLoginModalOpen);
  const toggleContactModal = () => setIsContactModalOpen(!isContactModalOpen);

  Auth.configure({
    region: config.region,
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolClientId,
    cookieStorage: {
      domain: window.location.hostname,
      path: '/',
      expires: config.cookieExpiryDays,
      secure: isProduction(),
    },
  } as AuthOptions);

  const onAuthStateChange = () => {
    if (!currentUser) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          if (!currentUser) {
            setCurrentUser(user);
            setCurrentUserEmail(user.attributes.email);
          }
        })
        .catch(() => console.log('Ignoring auth, user is not authenticated'));
    }
  };

  useEffect(() => {
    if (!currentUser) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          if (!currentUser) {
            setCurrentUser(user);
            setCurrentUserEmail(user.attributes.email);
          }
        })
        .catch(() => console.log('Ignoring auth, user is not authenticated'));
    }
  },        [currentUser, setCurrentUser]);

  useEffect(() => {
    const checkCurrentUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setCurrentUser(user);
        setCurrentUserEmail(user.attributes.email);
      } catch (error) {
        setCurrentUser(undefined);
        setCurrentUserEmail('');
      }
      setLoadingState(false);
    };

    checkCurrentUser();
  },        [isLoginModalOpen, isContactModalOpen]);

  const location = useLocation();

  const showSimpilifiedHeader = () => {
    // tslint:disable-next-line:ter-arrow-parens
    return !!['/privacy-policy'].find(route => location.pathname.includes(route));
  };

  const handleUserEmailChange = (email: string): void => {
    setCurrentUserEmail(email);
  };

  const handleLogout = async () => {
    await Auth.signOut();
    setCurrentUser(undefined);
  };

  const client = useMemo(() => createApolloClient(), []);
  if (loadingState) {
    return <Loading />;
  }
  return (
    <ApolloProvider client={client}>
      <div className={styles.EcosystemApp}>
        <EcosystemHeader
          isAuthenticated={!!currentUser}
          email={currentUserEmail}
          logout={handleLogout}
          simplified={showSimpilifiedHeader()}
          currentPath={location.pathname}
          toggleLoginModal={toggleLoginModal}
          toggleContactModal={toggleContactModal}
        />
        <LoginModal isOpen={isLoginModalOpen} toggle={toggleLoginModal}/>
        <ContactForm isOpen={isContactModalOpen} toggle={toggleContactModal}/>
        <div className={styles.EcosystemApp__Body}>
          <div className={styles.EcosystemApp__Body__MainContent}>
            <Switch>
              {/* Public routes */}
              <Route path="/privacy-policy" component={PrivacyPolicy} />

              {/* Routes for authenticated users */}
              {currentUser && currentUserEmail ? (
                <IconContext.Provider value={{ size: '1.5em', style: { verticalAlign: 'middle', margin: '0 5 0 0' } }}>
                  <Authenticator onAuthStateChange={onAuthStateChange}>
                    <LayoutWrapper>
                      <Switch>
                        <Route path="/" exact>
                          <Redirect to="/home" />
                        </Route>
                        <Route path="/news" exact component={News} />
                        <Route path="/news/:sectorKey" component={News} />
                        <Route path="/home" exact component={Home} />
                        <Route path="/releases" exact component={Dashboard} />
                        <Route path="/releases/:productCategoryKey" component={Dashboard} />
                        <Route path="/my-account" render={(props) => <MyAccount {...props} onUserEmailChange={handleUserEmailChange} />} />
                        <Route path="/release/:releaseKey/deliverable/:deliverableKey" component={DeliverableDownload} />
                        <Route path="/archive/:objectId" component={ArchiveDirectDownload} />
                        <Route path="/not-found" component={NotFound} />

                        <Redirect from="*" to="/not-found" />
                      </Switch>
                    </LayoutWrapper>
                  </Authenticator>
                </IconContext.Provider>
              ) : (
                <Route path="/">
                  <Landing toggleLoginModal={toggleLoginModal} toggleContactModal={toggleContactModal}/>
                </Route>
              )}
            </Switch>
          </div>
          <EcosystemFooter />
        </div>
      </div>
    </ApolloProvider>
  );
}

export default App;
